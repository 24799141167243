.Header {
  position: fixed;
  top: 0;
  background-color: white;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px 1px 1px #e9e9e9;
  -moz-box-shadow: 0px 1px 1px #e9e9e9;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  z-index: 50;
}

.HeaderLogo {
  max-width: 3%;
}
