.Form {
  margin: 0 auto;
  max-width: 400px;
}

.Form .ProfileCard {
  border: none;
  box-shadow: none;
  color: #6c757d;
}

.ProfileImage {
  background-size: cover;
  border-radius: 5px;
  background-color: #e9e9e9;
  float: left;
  width: 80px;
  height: 80px;
}

.Name {
  font-size: 18px;
  color: #6f6f6f;
}

.Username {
  font-size: 12px;
  font-weight: 400;
}

.Username:hover {
  color: #7b7b7b;
}

.Icon {
  color: #a1a1a1;
  font-size: 30px !important;
}

.Icon:hover {
  color: #7b7b7b;
}

.TextArea::placeholder {
  font-size: 15px !important;
  color: #a1a1a1 !important;
}
