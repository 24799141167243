$primary: #a1a1a1;
$success: #47b87e;
$warning: #fdc423;
$danger: #dc3545;
$body-bg: #e9e9e9;

@import '~bootstrap/scss/bootstrap.scss';

.btn {
  font-size: 12px;
  font-weight: 800;
  text-align: center;
  border-radius: 5px;
  padding: 12px;
}

a:hover {
  text-decoration: none;
}

.btn-primary-light {
  background-color: #c9c9c9;
  color: #797979;
}
.btn-primary-light:hover {
  color: #797979;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:hover,
.btn-primary {
  background-color: $primary;
  border: none;
  color: #ffffff;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:hover,
.btn-success {
  background-color: $success;
  border: none;
  color: #ffffff;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:hover,
.btn-warning {
  background-color: $warning;
  border: none;
  color: #ffffff;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:hover,
.btn-warning {
  background-color: $warning;
  border: none;
  color: #ffffff;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:hover,
.btn-danger {
  background-color: $danger;
  border: none;
  color: #ffffff;
}

.btn-primary:focus,
.btn-success:focus,
.btn-warning:focus,
.btn-danger:focus {
  box-shadow: none;
}

.form-control {
  border-radius: 5px;
}

.alert .btn.btn-icon.close {
  border: 2px solid white;
  border-radius: 50%;
  padding: 2px;
  min-width: auto;
  top: 25%;
  right: 5%;
}

.alert .btn.btn-icon.close:hover {
  color: white;
  opacity: 0.5;
}

.alert.alert-danger {
  background-color: red;
  color: white;
  border: 0;
  border-radius: 5px;
  height: 50px;
}

.selectgroup-input:checked + .selectgroup-button {
  background-color: #a1a1a1;
  color: white;
  border: 1px solid #a1a1a1;
}

.selectgroup-button {
  background-color: white;
  color: #a1a1a1;
  border: 1px solid #a1a1a1;
}

.selectgroup-input:focus + .selectgroup-button {
  outline: 0;
}

.custom-switch-input:focus ~ .custom-switch-indicator {
  box-shadow: 0 0 0 2px rgba(161, 161, 161, 0.25);
  border-color: rgba(0, 40, 100, 0.12);
}

.custom-switch-input:checked ~ .custom-switch-indicator {
  background: #a1a1a1;
}
