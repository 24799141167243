.StatMetric {
  font-weight: 700;
  color: #797979;
}

.StatMetric.small {
  font-size: 16px;
}

.StatMetric.large {
  font-size: 22px;
}

.StatCategory {
  color: #a1a1a1;
}

.StatCategory.small {
  font-size: 11px;
}

.StatCategory.large {
  font-size: 12px;
}

.Percentage:after {
  content: '%';
}
