.Form {
  margin: 0 auto;
  max-width: 400px;
  padding-top: 70px;
  text-align: left;
}

.ErrorMessages {
  padding-left: 15px;
}

.Form input {
  border-radius: 5px;
  border: 1px solid #ced4da;
}

.Form input:focus {
  box-shadow: 0 0 0 0.2rem rgba(161, 161, 161, 0.25);
  border-color: #e1e1e1;
}
