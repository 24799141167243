.Entry {
  padding: 12px 0;
  text-align: left;
  word-break: break-all;
}

.Entry:first-child {
  padding: 0 0 5px 0;
}

.EntryBody {
  margin: 0;
}

.Username {
  display: inline-block;
  font-weight: 600;
  padding: 0 7px 0 0;
}

.Time {
  display: block;
  font-size: 10px;
  color: #a1a1a1;
  margin: 5px 0 0 0;
}
