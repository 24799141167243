.InfluencerProfileDetails .ProfileCard {
  border: none;
  box-shadow: none;
  color: #6c757d;
}

.ProfileImage {
  background-size: cover;
  border-radius: 5px;
  background-color: #e9e9e9;
  float: left;
  width: 80px;
  height: 80px;
}

.Name {
  font-size: 24px;
  color: #6f6f6f;
}

.PersonalDetails {
  position: relative;
  font-size: 12px;
  word-break: break-all;
}

.PersonalDetails:after {
  content: '|';
  position: absolute;
  right: -0.1rem;
  top: 0;
}

.PersonalDetails:last-child:after {
  content: '';
}

.PersonalDetails a {
  color: #6c757d;
}

.InfluencerProfileDetails .VerticalLine {
  height: 60px;
  padding: 0 1px;
  background-color: #e9e9e9;
  border-radius: 5px;
}

:focus {
  outline: none;
}
