.Footer {
  color: #a1a1a1;
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 18px;
  text-align: center;
}

.TextRight {
  margin-left: 18px;
  color: #a1a1a1;
}

.TextRight:hover {
  text-decoration: none;
  color: #717171;
}

.CompanyText {
  font-weight: 900;
}
