.NoteCard {
  font-size: 12px;
  color: #6c757d;
  padding: 0 16px;
}

.NoteCard a {
  color: #6c757d;
}

.BorderRight {
  border-right: 1px solid #e9e9e9;
  border-radius: 0;
}

.Contact {
  border-bottom: 1px solid #e9e9e9;
}

.Title {
  font-size: 16px;
}

.NotesTitle {
  font-size: 16px;
  border-bottom: 1px solid #e9e9e9;
}

.NotesContainer {
  height: 200px;
  overflow: auto;
  list-style-type: none;
  margin: 0;
  padding: 10px 10px 0 0;
  border-bottom: 1px solid #e9e9e9;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 54px;
}

.Form {
  display: flex;
  flex-direction: row;
  padding: 0 8px;
}

.Form .Textarea {
  display: flex;
  flex-grow: 1;
  height: 18px;
  max-height: 54px;
  padding: 0;
  line-height: 1.5;
  color: #6c757d;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 0;
  box-shadow: inset 0 0 0 transparent;
  box-sizing: border-box;
  outline: none;
  resize: none;
}

.Form .Textarea::placeholder {
  color: #a1a1a1;
}

.Form .SubmitButton {
  display: flex;
  text-align: center;
  -webkit-appearance: initial;
  padding: 0 8px;
  border: none;
  border-radius: 0;
  color: #66afe9;
  font-weight: 800;
  background: 0 0;
  cursor: pointer;
}

.Form .SubmitButton:disabled,
.Form .SubmitButton:disabled:hover {
  color: #66afe9;
}

.Form .SubmitButton:hover {
  color: #6c757d;
}

.Form .SubmitButton:focus {
  box-shadow: none;
}
