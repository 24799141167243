.Title {
  font-size: 1.17em;
  color: #6f6f6f;
  font-weight: bold;
}

.Table {
  font-size: 12px;
  margin-bottom: 0px !important;
}

.Table .TableHeader {
  border-bottom-width: 1px;
}

.Table .TableCell {
  color: #a1a1a1;
}
