.FormTitle {
  clear: both;
  color: #797979;
  display: inline-block;
  font-size: 1.5em;
  text-transform: capitalize;
  font-weight: 700;
}

.FormSubtitle {
  color: #797979;
  display: block;
}

.FormLabel {
  font-size: 10px !important;
  text-transform: uppercase;
  color: #7a797c !important;
  margin-bottom: 0.375rem;
}

.FormInput {
  width: 100%;
  font-size: 12px !important;
  border: none !important;
  border-radius: 7px !important;
  outline: none !important;
  color: #6f6f6f !important;
}

.Avatar {
  width: 180px;
  height: 180px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 5px;
  font-size: 30px;
  color: #6f6f6f;
  font-weight: bold;
  cursor: pointer;
  background-size: cover;
}
