.ActionContainer {
  display: flex;
  justify-content: space-between;
}

.ActionApprove {
  font-size: 13px;
  font-weight: 700;
  border-radius: 5px;
  color: #fff;
  padding: 8px 30px;
  margin: 10px 5px 10px 0px;
}

.ActionChange {
  font-size: 13px;
  font-weight: 700;
  border-radius: 5px;
  color: #797979 !important;
  padding: 8px 30px;
  margin: 10px 0px 10px 5px;
}
