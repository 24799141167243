.ErrorCode {
  font-size: 48px;
  font-weight: 700;
  color: #797979;
}

.ErrorMessage {
  font-size: 14px;
  color: #a1a1a1;
}
