.platform-filter {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0 10px;
}

.platform-filter .selectgroup-button {
  padding: 15px 0;
  color: #797979;
}

.platform-filter .selectgroup-input:checked + .selectgroup-button {
  background-color: #fff;
  color: #66afe9 !important;
  border-bottom: 2px solid #66afe9 !important;
  border-radius: 0;
  margin-bottom: -1px;
  z-index: 0;
}

.platform-filter .selectgroup-item:not(:first-child) .selectgroup-button,
.platform-filter .selectgroup-item:not(:last-child) .selectgroup-button {
  border: none;
}

.platform-filter .selectgroup-item {
  padding: 0 20px;
}
.platform-filter label {
  margin: 0;
}
