.InstagramStoryContainer {
  width: 100%;
  max-width: 500px;
}

.MediaBox {
  background: #fff;
  margin-bottom: 20px;
  box-shadow: 0 7px 6px -6px #777;
}

.InstagramStoryContainerCol {
  position: relative;
  margin-bottom: -10px;
}

.InstagramStoryProfile {
  position: absolute;
}

.InstagramStoryAvatar {
  width: 50px;
  height: 50px;
  display: block;
  border-radius: 50px;
  background-color: #e7e7e7;
  float: left;
}

.InstagramStoryHandle {
  margin-top: 10px;
  color: #fff;
  font-weight: 600;
}

.InstagramStoryVideo {
  background: #c9c9c9;
}

.InstagramStoryCaptionContainer {
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  box-shadow: 0 7px 6px -6px #777;
}

.InstagramStoryCaption {
  text-align: left;
  padding: 20px;
  color: #797979;
  overflow-wrap: break-word;
  word-wrap: break-word;
  line-height: normal;
}
