.SubTitle {
  color: #797979;
  display: block;
}

.Title {
  clear: both;
  color: #797979;
  display: inline-block;
  font-size: 1.5em;
  text-transform: capitalize;
  font-weight: 700;
}
