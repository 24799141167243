.InfluencersListPage {
  margin: 0;
  padding: 10px 30px;
  width: 100%;
}

.InfluencersListPage.Open {
  margin-left: 285px !important;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

.InfluencersListPage.Closed {
  margin-left: 45px !important;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

.InfluencersListPage .Icon {
  font-size: 30px;
  color: #797979;
  margin-right: 10px;
}
