.ProfileImage {
  display: block;
  float: left;
  border-radius: 5px;
  background-color: #e9e9e9;
  width: 100px;
  height: 100px;
}

.Thumbnail {
  display: block;
  border-radius: 5px;
  width: 100px;
  height: 100px;
}

.PostHistoryCard {
  font-size: 12px;
  color: #6c757d;
}

.PostHistoryData {
  display: flex;
  flex-direction: column;
  justify-content: left;
  height: 100%;
}

.DataSubTitle {
  font-size: 12px;
  color: #a1a1a1;
}

.PostHistoryCard .Icon {
  position: absolute;
  right: 13px;
  top: 0;
  font-size: 1.4em;
}
