.InfluencerWidget {
  font-size: 18px;
  font-weight: 700;
  color: #797979;
  text-align: left;
  height: 100%;
}

.ProfileImage {
  background-size: cover;
  border-radius: 5px;
  background-color: #e9e9e9;
  float: left;
}

.Info {
  font-size: 10px;
  color: #a1a1a1;
  font-weight: normal;
  text-transform: capitalize;
}

.Info span:after {
  content: ' | ';
}

.Info span:last-child:after {
  content: '';
}

.InfluencerData {
  padding-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.DataTitle {
  font-weight: 700;
}

.DataSubTitle {
  font-size: 12px;
  color: #a1a1a1;
}

.DataSubTitle:before {
  content: '@';
}
