.SideDrawer {
  position: fixed;
  width: 600px;
  max-width: 70%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 200;
  background-color: white;
  padding: 32px 16px;
  box-sizing: border-box;
  transition: all 0.5s ease;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
}

.Open {
  transform: translateX(0);
}

.Closed {
  transform: translateX(-300%);
}

.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: hsla(0, 0%, 91.4%, 0.95);
  color: transparent;
  transition: all 0.5s ease;
  visibility: hidden;
}

.BackdropActive {
  opacity: 1;
  transition: all 0.5s ease;
  visibility: visible;
}
