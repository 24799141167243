.Form {
  margin: 0 auto;
  max-width: 400px;
  padding-top: 70px;
}

.PencilIcon {
  color: #797979;
  padding: 3px;
  cursor: pointer;
}
