.Bar {
  height: 12px;
  margin: 0 0 2px;
  background-color: rgba(62, 123, 255, 0.7);
}

.Label {
  font-size: 12px;
  font-weight: 400;
  color: hsla(0, 0%, 63.1%, 0.8);
  text-transform: capitalize;
}

.NoDataLabel {
  font-size: 12px;
  color: rgba(161, 161, 161, 0.8);
}
