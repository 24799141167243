.LoginButton {
  border: none;
  background: none;
}

.LoginButton:hover {
  text-decoration: underline;
}

.LoginButton:focus,
.LoginButton:active {
  outline: none;
}
