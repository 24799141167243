.DemographicsTitle {
  font-size: 1.17em;
  color: #6f6f6f;
  font-weight: bold;
}

.DemographicsUpdatedAt {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: rgba(161, 161, 161, 0.8);
}
