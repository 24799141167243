.EditButton {
  border-radius: 5px;
  color: #797979 !important;
  background-color: #c9c9c9;
  border: 1px solid #c7c7c7;
  font-size: 13px !important;
  font-weight: 700 !important;
  padding: 8px 30px !important;
  margin: 10px !important;
}
