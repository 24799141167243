.SidebarFolded {
  position: absolute;
  width: 60px;
  top: 0;
  right: 0;
  background-color: #fff;
  padding: 80px 0 20px 0 !important;
}

.Form {
  margin: 85px 0 30px 0;
}

.Form input {
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.Form .Label {
  display: block;
  position: relative;
  text-align: center;
  border-radius: 50%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 20px;
  padding: 7px;
  width: 40px;
  height: 40px;
  margin: 0 10px;
  color: #a1a1a1;
  cursor: pointer;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.Form .Label:hover {
  background-color: #f5f5f5;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
