.ShortlistedProfileDetails .ProfileCard {
  border: none;
  box-shadow: none;
  color: #a1a1a1;
}
.ProfileImage {
  background-size: cover;
  border-radius: 5px;
  background-color: #e9e9e9;
  float: left;
  width: 100px;
  height: 100px;
}

.Image {
  border-radius: 5px;
  background-size: cover;
  background-color: #e9e9e9;
  height: 250px;
  width: 48%;
  margin: 1%;
  float: left;
}

.Name {
  font-size: 32px;
  color: #6f6f6f;
}

.Nickname {
  text-transform: lowercase;
}
.Username:before {
  content: '@';
}

.Icons {
  color: #a1a1a1;
  font-size: 30px;
}

.Bio,
.Rationale,
.RejectionReason {
  color: #a1a1a1;
  font-size: 12px;
}

.Stats .value {
  font-size: 22px;
}
.Stats .label {
  font-size: 12px;
}
