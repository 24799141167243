.InfluencerContentCard {
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  transition: all 0.5s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #797979;
  cursor: pointer;
  text-align: left;
  border: none;
}

.InfluencerContentStats {
  padding-top: 20px;
}

.InfluencerContentStat {
  text-align: center;
  padding: 0 !important;
}

.StatValue {
  font-size: 16px;
  margin: 0;
}

.StatLabel {
  font-weight: 400;
  text-align: center;
  font-size: 12px;
  margin: 0;
  text-transform: uppercase;
}
