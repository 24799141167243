.Textarea {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  box-shadow: inset 0 0 0 transparent;
  box-sizing: border-box;
  outline: none;
  resize: none;
}

.SubmitButton {
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 8px 30px;
  border: 1px solid #c7c7c7;
  color: #fff;
  border: 1px solid #29a565;
  background-color: #47b87e;
  margin: 10px 0;
  width: 100%;
}

.SubmitButton:disabled {
  background-color: #acdfc5;
}
