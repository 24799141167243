.MainContent {
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 1320px) {
  .ShortlistInnerContainer {
    margin: 0 auto;
    width: 80%;
  }
}

@media (max-width: 800px) {
  .ShortlistInnerContainer {
    width: 100%;
  }
}
