.Form {
  margin: 0 auto;
  max-width: 400px;
  padding-top: 70px;
  text-align: left;
}

.ProfileUsername {
  text-transform: none;
}

.ProfileUsername:before {
  content: '@';
}

.Heading {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.EditShortlistedProfileForm .AudienceDataInput {
  height: 0;
}
