.Drawer {
  width: 400px;
  background-color: white;
  opacity: 0;
  transition: all 0.5s ease;
  padding-bottom: 0;
}

.DrawerActive {
  opacity: 1;
  transition: all 0.5s ease;
}

.Drawer *:focus {
  outline: none;
}

.Drawer .CloseBtn {
  padding: 0 20px;
  line-height: 50px;
  font-size: 14px;
}

.Wrapper {
  min-height: 95%;
}

.MenuLink {
  color: #a1a1a1;
  background: none;
  border: none;
  position: absolute;
  left: 15px;
}

.MenuLink i {
  line-height: 80px;
  font-size: 39px;
}

.MenuLink:active,
.MenuLink:focus {
  outline: none;
}

.Avatar {
  width: 7.5rem;
  height: 7.5rem;
  background: #e9e9e9;
}

.ProfileName {
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  color: #6f6f6f;
}

.NavContainer {
  width: 100%;
  text-align: center;
  padding: 20px 0 50px 0;
}

.NavContainer a {
  color: #d3d3d3;
}

.NavList {
  width: 100%;
  list-style-type: none;
}

.NavList li {
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  display: block;
  margin-top: 1.7rem;
}

.NavList .ActiveLink {
  color: #797979;
  border-bottom: 2px solid #797979;
}

.NavList li :hover {
  text-decoration: none;
}

.DrawerFooter {
  bottom: 0;
  padding: 10px 0;
  background: #fff;
  margin: 0;
  position: sticky;
}
