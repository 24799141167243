.StateLabel {
  flex-grow: 1;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
  border-radius: 5px;
  color: #fff;
  padding: 15px 30px;
  margin: 10px 0px 10px 0px;
  line-height: 1;
}

.StatePosted {
  border: 0;
  background-color: rgba(102, 175, 233, 0.5);
}

.StateApproved {
  border: 0;
  background-color: rgba(71, 184, 126, 0.5);
}

.StatePending {
  border: 0;
  background-color: hsla(0, 0%, 76.1%, 0.4);
  color: #797979;
}

.StateChangesRequested {
  background: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 7px 6px -6px #777;
  border: 1px solid #d0d0d0;
  font-size: 13px;
  padding: 15px 30px;
  margin: 10px 0px 10px 0px;
  line-height: 1;
}

.BlockQuote {
  text-align: left;
  background: #f9f9f9;
  border-left: 5px solid #e7e7e7;
  line-height: 1.5;
  padding: 0.5em 10px;
  quotes: '\201C''\201D''\2018''\2019';
  font-style: normal;
  font-weight: normal;
  text-transform: none;
}

.BlockQuote::before {
  color: #e7e7e7;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

.BlockQuoteAuthor {
  color: #797979;
  font-weight: 600;
}

.BlockQuoteContent {
  display: inline !important;
  overflow-wrap: break-word !important;
  text-align: start;
}

.BlockQuoteLabel {
  color: #797979;
  font-weight: 500;
  text-transform: lowercase;
}
