.gendersChart .domain {
  display: none;
}

.gendersChart .tick {
  font-size: 12px;
  opacity: 0.4 !important;
}

.gendersChart .c3-text {
  font-size: 12px;
  fill: #000 !important;
  opacity: 0.4 !important;
}
