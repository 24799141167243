.Card {
  padding: 20px;
  border-radius: 5px !important;
  color: #797979;
}

.Card .Title {
  font-size: 16px;
  font-weight: 700;
  color: #797979;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Card .Button {
  padding: 15px 0;
  font-size: 12px;
  font-weight: 700;
}

.Card .Button:disabled {
  color: white;
  opacity: 0.4;
  cursor: default;
}

.Card .VerticalLine {
  height: 60px;
  padding: 0 1px;
  background-color: #e9e9e9;
  border-radius: 5px;
}

@media (max-width: 420px) {
  .Card .VerticalLine {
    display: none;
  }
}

.Card .Logo {
  background-size: 80%;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.Card .ClientName {
  font-size: 28px;
  font-weight: 800;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Card .Number {
  font-size: 40px;
  font-weight: 800;
  text-transform: lowercase;
  line-height: 1em;
  color: #6473ff;
}

.Card .Text {
  font-size: 14px;
  color: #a1a1a1;
}
