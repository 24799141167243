.RationaleHeader {
  font-size: 12px;
  font-weight: 700;
  color: #797979;
}

.RationaleContent {
  font-size: 12px;
  color: #a1a1a1;
}
