.Form {
  margin: 0 auto;
  max-width: 400px;
  padding-top: 70px;
}
.ReportFileLabel {
  cursor: pointer;
}
.Form .InvalidLabel {
  border-color: #dc3545;
}

.Form .ReportFileInput {
  height: 0;
  padding: 0;
}
.Form .InvalidFeedback {
  display: block;
  margin-left: -13px;
}
