html {
  height: 100%;
}

body {
  overflow: hidden;
  height: 100%;
  margin: 0;
  font-family: Helvetica, Arial, Lucida Grande, sans-serif;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

.page-main {
  position: fixed;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
}

.scrollableContainer {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.cardItem {
  transition: all 0.5s ease;
}

h2 {
  color: #979797;
  display: inline-block;
  font-size: 1.5em;
  text-transform: capitalize;
  font-weight: 700;
}

input.form-control:active,
input.form-control:focus {
  outline: none;
  box-shadow: none;
}

input.form-control {
  clear: both;
  display: block;
  box-sizing: border-box;
  font-size: 14px;
  height: 46px;
  padding: 12px;
  outline: none;
  border-radius: 4px;
  border: 3px solid #e9e9e9;
}

span.custom-control-label:active {
  outline: none;
}

span.custom-control-label {
  display: flex;
  vertical-align: middle;
}

span.custom-control-label,
label.form-label {
  color: #979797;
  font-weight: normal;
  font-size: 1rem;
}

.selectgroup-button {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.custom-switch-description {
  color: #a1a1a1;
}

.selectgroup-input:focus + .selectgroup-button {
  box-shadow: none;
  border-color: #a1a1a1;
  color: #a1a1a1;
}

.selectgroup-input:checked:focus + .selectgroup-button {
  box-shadow: none;
  color: #fff;
}

.c-pointer {
  cursor: pointer;
}

.fe-instagram.colored {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fa-youtube-play.colored {
  color: #ff0000;
}
