.Sidebar {
  top: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  transition: all 0.5s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  overflow-y: auto;
  padding: 80px 0 20px 0 !important;
  z-index: 10;
}
