.Beta {
  border: 1px solid #3cb55b;
  border-radius: 5px;
  padding: 5px;
}

.Badge {
  background-color: #3cb55b;
  border-radius: 5px;
  color: #ffffff;
  font-family: Arial, sans-serif;
  font-size: 10px;
  line-height: 10px;
  padding: 4px 6px;
  margin-top: -13px;
  width: 35px;
  margin-bottom: 5px;
}
