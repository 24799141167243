.Card {
  padding: 20px;
  border-radius: 5px !important;
}

.Card .Title {
  font-size: 16px;
  font-weight: 700;
  color: #797979;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Card .Button {
  padding: 15px 0;
  font-size: 12px;
  font-weight: 700;
}

.Card .Button:disabled {
  color: white;
  opacity: 0.4;
  cursor: default;
}

@media (max-width: 576px) {
  .Card .Button {
    padding: 10px 0;
    font-size: 10px;
  }
}
@media (max-width: 320px) {
  .Card .Button {
    font-size: 8px;
  }
}

.Card .AgencyName {
  min-height: 16px;
  font-size: 14px;
  color: #a1a1a1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
