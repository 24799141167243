/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
  outline: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #a1a1a1;
}
