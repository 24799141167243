.StateMarker {
  line-height: 0.8;
}

.StateMarker .State {
  display: inline-block;
  height: 5px;
  border-radius: 5px;
}

.StateMarker .Posted {
  background-color: #66afe9;
}

.StateMarker .Approved {
  background-color: #47b87e;
}

.StateMarker .Pending {
  background-color: #c9c9c9;
}

.StateMarker .ChangesRequested {
  background-color: #ffc963;
}
