.Card {
  padding: 0 20px 30px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  box-shadow: 0 5px 6px -6px #777;
  margin: 0 0 15px;
  background-color: #e9e9e9 !important;
  color: #797979;
  text-align: left;
  cursor: pointer;
}

.CardButton:focus,
.CardButton:active {
  outline: none;
}
