.Form {
  margin: 0 auto;
  max-width: 400px;
  padding-top: 70px;
}
.ActionContainer {
  display: flex;
  justify-content: space-between;
}

.ActionApprove {
  font-size: 13px;
  font-weight: 700;
  border-radius: 5px;
  color: #fff;
  padding: 8px 30px;
  margin: 10px 5px 10px 10px;
}
.ActionReject {
  font-size: 13px;
  font-weight: 700;
  border-radius: 5px;
  color: #fff;
  padding: 8px 30px;
  margin: 10px 10px 10px 5px;
}
