.react-grid-Container {
  font-size: 14px;
  color: #a1a1a1;
}
.react-grid-Main {
  outline: none;
}

.react-grid-Grid {
  border-radius: 5px !important;
}

.react-grid-Grid,
.react-grid-Header,
.react-grid-HeaderCell {
  border: none !important;
  font-weight: normal;
}

.react-grid-Cell {
  border-right: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.react-grid-HeaderCell {
  color: #797979;
  padding-top: 13px !important;
}

.rdg-selected {
  border: 2px solid #66afe9;
}

.rdg-editor-container input.editor-main,
select.editor-main {
  padding: 0 5px;
  color: #797979;
}

input.editor-main:focus,
select.editor-main:focus {
  border: 2px solid #66afe9;
  background: #fff;
  border-radius: 4px;
}

.rdg-editor-container input.editor-main::-moz-placeholder,
select.editor-main::-moz-placeholder,
.rdg-editor-container input.editor-main:-ms-input-placeholder,
select.editor-main:-ms-input-placeholder,
.rdg-editor-container input.editor-main::-webkit-input-placeholder,
select.editor-main::-webkit-input-placeholder {
  color: #797979;
}

.react-grid-Cell:not(.editing) .react-grid-Cell__value > div > span > div,
.widget-HeaderCell__value {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.react-grid-Cell__value .fe {
  font-size: 20px;
  color: #a1a1a1;
  cursor: pointer;
}

.react-grid-Cell__value .fe:hover {
  color: #717171;
}

.react-grid-Canvas {
  min-height: 45px !important;
  margin-left: 3px !important;
  overflow: hidden !important;
}

.react-grid-Canvas > div {
  width: 100% !important;
}

.react-grid-Row {
  height: 45px !important;
}
.react-grid-Row:last-child .react-grid-Cell {
  border-bottom: 0 !important;
}

.color {
  color: #66afe9;
}
