.Template {
  width: 100%;
  max-width: 700px;
}

.YoutubeCardContainer {
  background: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 7px 6px -6px #777;
  border: 1px solid #d0d0d0;
}

.YoutubeCardBorder {
  border-bottom: 1px solid #eee;
}

.YoutubeCardHeaderTitle {
  color: #0d0d0d;
  font-size: 18px;
  font-weight: 500;
}

.YoutubeCardFadedText {
  background: #e7e7e7;
  height: 10px;
  border-radius: 5px;
}

.YoutubeCardDotAction {
  background: #e7e7e7;
  width: 5px;
  height: 5px;
  border-radius: 5px;
}

.YoutubeCardAvatar {
  width: 50px;
  height: 50px;
  display: block;
  border-radius: 50px;
  background-color: #e7e7e7;
  float: left;
}

.YoutubeCardAvatarTitle {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.YoutubeCardFadedButton {
  background-color: #fbbdbd;
  height: 4vh;
}

.YoutubeCardDescription {
  color: #000;
  font-size: 14px;
  line-height: 1.3rem;
}
