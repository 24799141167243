.Card {
  border-radius: 5px !important;
  background-color: #f6f6f6 !important;
  border: none !important;
  width: 75%;
  padding: 25px 10px 15px;
  margin: 30px auto;
}

.Card .Center {
  margin: 0 auto;
  color: #b7b7b7;
}

.Card .Title {
  font-size: 12px;
}

.Card .Value {
  color: #979797;
}

.Card .Stats {
  font-weight: 700;
  font-size: 20px;
}
