.AppLoader {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  transition: all 0.5s ease;
  pointer-events: auto;
  z-index: 9999;
  opacity: 1;
  background-color: #e9e9e9;
}

.AppLoader .AppLoaderMessage {
  height: 20px;
  line-height: 20px;
  font-size: 20px;
  text-align: center;
  position: absolute;
  top: 65%;
  left: 0px;
  right: 0px;
  margin-top: 15px;
  transition: all 0.5s ease;
  color: #a1a1a1;
  font-weight: bold;
}

.AppLoader .AppLoaderMessage b {
  color: #a1a1a1;
}

.LoaderSpinner {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  filter: url('#goo');
  animation: rotate-move 2s ease-in-out infinite;
}
.Dot {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.Dot3 {
  background-color: rgb(229, 0, 78);
  animation: dot-3-move 2s ease infinite, index 6s ease infinite;
}
.Dot2 {
  background-color: rgb(243, 146, 0);
  animation: dot-2-move 2s ease infinite, index 6s -4s ease infinite;
}
.Dot1 {
  background-color: rgb(199, 211, 0);
  animation: dot-1-move 2s ease infinite, index 6s -2s ease infinite;
}
@keyframes dot-3-move {
  20% {
    transform: scale(1);
  }
  45% {
    transform: translateY(-8px) scale(0.45);
  }
  60% {
    transform: translateY(-70px) scale(0.45);
  }
  80% {
    transform: translateY(-70px) scale(0.45);
  }
  100% {
    transform: translateY(0px) scale(1);
  }
}
@keyframes dot-2-move {
  20% {
    transform: scale(1);
  }
  45% {
    transform: translate(-6px, 2px) scale(0.45);
  }
  60% {
    transform: translate(-60px, 40px) scale(0.45);
  }
  80% {
    transform: translate(-60px, 40px) scale(0.45);
  }
  100% {
    transform: translateY(0px) scale(1);
  }
}
@keyframes dot-1-move {
  20% {
    transform: scale(1);
  }
  45% {
    transform: translate(6px, 2px) scale(0.45);
  }
  60% {
    transform: translate(60px, 40px) scale(0.45);
  }
  80% {
    transform: translate(60px, 40px) scale(0.45);
  }
  100% {
    transform: translateY(0px) scale(1);
  }
}
@keyframes rotate-move {
  55% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  80% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes index {
  0%,
  100% {
    z-index: 3;
  }
  33.3% {
    z-index: 2;
  }
  66.6% {
    z-index: 1;
  }
}
