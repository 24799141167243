.ContentGalleryItem {
  display: table;
  margin-bottom: 16px;
  position: relative;
  transition: 0.4s ease-in-out;
}

.ContentGalleryItem:hover {
  transform: scale(1.01);
  filter: contrast(120%);
  transition: 0.4s ease-in-out;
  cursor: pointer;
}

.MediaContainer {
  display: table-row;
}

.Media {
  display: table-row;
  border-radius: 5px;
}

.Dim {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.34) 0,
    rgba(0, 0, 0, 0.338) 3.5%,
    rgba(0, 0, 0, 0.324) 7%,
    rgba(0, 0, 0, 0.306) 10.35%,
    rgba(0, 0, 0, 0.285) 13.85%,
    rgba(0, 0, 0, 0.262) 17.35%,
    rgba(0, 0, 0, 0.237) 20.85%,
    rgba(0, 0, 0, 0.213) 24.35%,
    rgba(0, 0, 0, 0.188) 27.85%,
    rgba(0, 0, 0, 0.165) 31.35%,
    rgba(0, 0, 0, 0.144) 34.85%,
    rgba(0, 0, 0, 0.126) 38.35%,
    rgba(0, 0, 0, 0.112) 41.85%,
    rgba(0, 0, 0, 0.103) 45.35%,
    rgba(0, 0, 0, 0.1) 48.85%,
    rgba(0, 0, 0, 0.103) 52.35%,
    rgba(0, 0, 0, 0.112) 55.85%,
    rgba(0, 0, 0, 0.126) 59.35%,
    rgba(0, 0, 0, 0.144) 62.85%,
    rgba(0, 0, 0, 0.165) 66.35%,
    rgba(0, 0, 0, 0.188) 69.85%,
    rgba(0, 0, 0, 0.213) 73.35%,
    rgba(0, 0, 0, 0.237) 76.85%,
    rgba(0, 0, 0, 0.262) 80.35%,
    rgba(0, 0, 0, 0.285) 83.85%,
    rgba(0, 0, 0, 0.306) 87.35%,
    rgba(0, 0, 0, 0.324) 90.85%,
    rgba(0, 0, 0, 0.338) 94.35%,
    rgba(0, 0, 0, 0.347) 97.85%,
    rgba(0, 0, 0, 0.35)
  );
  border-radius: 5px;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}

.ContentGalleryItem:hover .Dim {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}

.TopRow {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  display: flex;
  top: 10px;
  right: 10px;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}

.ExternalLink {
  display: inline-block;
  color: #fff;
  opacity: 0.8;
  transition: opacity 0.4s ease-in-out;
}

.ExternalLink i {
  font-size: 25px;
  text-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.BottomRow {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  display: flex;
  height: 32px;
  bottom: 10px;
  padding: 0 10px;
  width: 100%;
  pointer-events: none;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}

.ContentGalleryItem:hover .BottomRow,
.ContentGalleryItem:hover .TopRow {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}

.Influencer {
  flex: 1;
  min-width: 0;
  margin-right: 8px;
  pointer-events: auto;
  display: inline-flex;
  align-items: center;
  opacity: 0.8;
  transition: opacity 0.4s ease-in-out;
}

.Influencer:hover,
.ExternalLink:hover {
  color: #fff;
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.Avatar {
  display: inline-block;
  max-width: 100%;
  min-width: 30px;
  width: 30px;
  height: 30px;
  margin-right: 8px;
  border-radius: 50%;
  background: #e9e9e9 no-repeat 50% / cover;
}

.Name {
  position: relative;
  display: block;
  font-size: 15px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}
