.NotUploaded {
  width: 100%;
  min-height: 400px;
  background-color: #e9e9e9;
  color: #797979;
}

.Video:focus {
  outline: none;
}
