.Contents {
  padding: 10px 0 0;
}

.Contents .Label {
  font-size: 10px;
  font-weight: 700;
}

.Contents .Thumbnail {
  display: block;
  border-radius: 5px;
  margin: 5px 0 0;
}
