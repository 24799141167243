.LoginForm .FormInput {
  font-size: 14px;
  border: 0;
  height: 50px;
}

.LoginForm .FormInput:focus {
  box-shadow: none;
}

.LoginForm .FormSubmit {
  font-size: 14px;
}

@keyframes fadeInZoom {
  from {
    opacity: 0;
    transform: scale(0.7, 0.7);
  }
  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.FadeInZoom {
  animation-duration: 0.7s;
  animation-name: fadeInZoom;
}
