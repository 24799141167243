.InfluencerInfo .ProfileCard {
  border: none;
  box-shadow: none;
  color: #a1a1a1;
}
.ProfileImage {
  background-size: cover;
  border-radius: 5px;
  background-color: #e9e9e9;
  float: left;
  width: 100px;
  height: 100px;
}

.Name {
  font-size: 32px;
  color: #6f6f6f;
}

.NavList li {
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  display: block;
  margin-top: 1rem;
}

.NavList a {
  color: #d3d3dd;
}

.NavList .ActiveLink {
  color: #797979;
  border-bottom: 2px solid #797979;
}
