.SidebarContainer {
  top: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  z-index: 11;
}

.SidebarContainer.Open {
  transform: translateX(0);
}

.SidebarContainer.Closed {
  transform: translateX(-240px);
}

.SidebarContainer .CloseButton {
  position: absolute;
  height: 40px;
  width: 40px;
  min-width: 40px;
  top: 102px;
  right: 10px;
  background-color: #fff;
  border-radius: 50%;
  padding: 0;
  color: #a1a1a1;
  z-index: 11;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.SidebarContainer .CloseButton:hover {
  background-color: #f5f5f5;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.SidebarContainer .CloseButton:focus {
  box-shadow: none;
}
