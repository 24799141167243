.LogoContainer {
  position: fixed;
  top: 0;
  width: 100%;
  height: 12vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4vh;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(0%);
  }
}

.SlideDown {
  animation-duration: 1s;
  animation-name: slideDown;
}
