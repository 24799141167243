.Form {
  margin: 0 auto;
  max-width: 400px;
  padding-top: 70px;
  text-align: left;
}

.SelectAreaContainer {
  display: flex;
  justify-content: space-between;
}

.SelectArea {
  width: 49%;
}
