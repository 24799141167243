.LargeImageContainer {
  position: relative;
  background-color: #e9e9e9;
  background-size: cover;
  height: 150px;
  width: 50%;
}

.SmallImageContainer {
  position: relative;
  background-color: #e9e9e9;
  background-size: cover;

  height: 110px;
  width: 33.33%;
}

.LargeImageDivider {
  width: 5px;
  position: absolute;
  right: -2.5px;
  top: 0;
  bottom: 0;
  z-index: 9;
  background-color: #fff;
}

.SmallImageLeftDivider {
  width: 5px;
  position: absolute;
  top: 0;
  left: -2.5px;
  bottom: 0;
  z-index: 9;
  background-color: #fff;
}

.SmallImageRightDivider {
  width: 5px;
  position: absolute;
  top: 0;
  right: -2.5px;
  bottom: 0;
  z-index: 9;
  background-color: #fff;
}

.HorizontalDivider {
  height: 5px;
}
