.NoDataLabel {
  margin-top: 32vh;
}

.CardsList:last-child::after {
  content: '';
  box-sizing: border-box;
  width: 32%;
}
@media (max-width: 1075px) {
  .CardsList:last-child::after {
    display: none;
  }
}

@media (max-width: 1320px) {
  .CardsList:last-child::after {
    width: 49%;
  }
}
