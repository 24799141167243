.Footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(0%);
  }
}

.SlideUp {
  animation-duration: 1s;
  animation-name: slideUp;
}
