.StateLabel {
  flex-grow: 1;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
  border-radius: 5px;
  color: #fff;
  padding: 12px 30px;
  margin: 10px;
}

.StateApproved {
  border: 0;
  background-color: rgba(71, 184, 126, 0.5);
}

.StateRejected {
  border: 0;
  background-color: rgba(237, 0, 52, 0.4);
}

.StatePending {
  border: 0;
  background-color: hsla(0, 0%, 76.1%, 0.4);
  color: #797979;
}
