.SubmitButton {
  color: #797979 !important;
}

.TextArea {
  resize: none;
}

.Form {
  margin: 0 auto;
  max-width: 400px;
  padding-top: 70px;
}
