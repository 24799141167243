.Thumb {
  height: 17px;
  width: 17px;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 2px #8a8a8a;
  transition: box-shadow 0.3s ease;
}

.ThumbTooltip {
  width: 70px;
  position: absolute;
  top: 22px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 5px;
  background-color: #a1a1a1;
  text-align: center;
}

.MaxValueTooltip {
  top: -32px;
}

.Track {
  height: 80px;
  display: flex;
  width: 100%;
}

.TrackLine {
  height: 2px;
  width: 80%;
  margin: 0 auto;
  border-radius: 4px;
  align-self: center;
}
