.Template {
  width: 100%;
  max-width: 500px;
}

.InstagramFeedContainer {
  background: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 7px 6px -6px #777;
  border: 1px solid #d0d0d0;
}

.InstagramFeedAvatar {
  width: 50px;
  height: 50px;
  display: block;
  border-radius: 50px;
  background-color: #e7e7e7;
  float: left;
}

.InstagramFeedFadedText {
  background: #e7e7e7;
  height: 10px;
  border-radius: 5px;
}

.InstagramFeedDotAction {
  background: #e7e7e7;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.InstagramFeedText {
  font-size: 14px;
  color: #797979;
}

.Caption {
  overflow-wrap: break-word;
  word-wrap: break-word;
  line-height: normal;
}
