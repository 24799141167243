.Masonry {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -16px;
  width: auto;
}

.MasonryColumn {
  padding-left: 16px;
  background-clip: padding-box;
}
