.Form {
  max-width: 400px;
  margin: 0 auto;
  color: #979797;
}

.Dropzone {
  border: 1px dashed #47b87e;
  border-radius: 5px;
  padding: 10px;
  background-color: #fbfbfb;
  height: 132px;
  cursor: pointer;
}

.DisabledDropzone {
  display: none;
}

.RemoveFilesButton {
  cursor: pointer;
  color: #47b87e;
  float: right;
}

.RemoveFilesButton:hover {
  text-decoration: underline;
}

.DropMessage {
  font-weight: 400px;
  text-align: center;
  margin: 3em auto;
}

.FilesGallery {
  padding: 10px;
  overflow: hidden;
}

.ImgPreview {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 16px;
  min-height: 100px;
}

.CarouselStatus {
  background: #000;
  border-radius: 15px;
  font-size: 14px;
  padding: 7px 12px;
  margin: 15px;
  text-shadow: none;
  opacity: 0.7;
  letter-spacing: 2px;
  display: inline-block;
  margin: 0 0 5px 0;
}

.DroppedImage {
  border-radius: 20px;
  overflow: hidden;
  width: 145px;
  height: 145px;
  position: relative;
  display: block;
  z-index: 10;
  background-color: #fff;
}

.DroppedImage img,
.DroppedImage span {
  position: absolute;
  max-width: 300px;
  top: -145px;
  bottom: -145px;
  left: -145px;
  right: -145px;
  margin: auto;
}

.DroppedImage span.VideoIcon {
  position: static;
  height: 100%;
  background-repeat: no-repeat;
  display: block;
}

.FileError {
  font-style: italic;
  color: #cc0000;
  font-size: 14px;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.ErrorMessages {
  padding-left: 15px;
}

.ListUnstyled {
  list-style-type: none;
  padding: 0;
}

.Form .Textarea {
  font-size: 14px;
}

.Form input.Price {
  border-radius: 5px;
  border: 1px solid #ced4da;
}

.Form input.Price:focus {
  box-shadow: 0 0 0 0.2rem rgba(161, 161, 161, 0.25);
  border-color: #e1e1e1;
}
