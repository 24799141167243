.GenderLabel {
  line-height: 1;
  padding: 10px 0 0 5px;
  font-size: 17px;
  font-weight: 700;
  color: #a1a1a1;
  float: left;
}

.GenderLabel span {
  font-size: 12px;
  font-weight: 400;
  color: hsla(0, 0%, 63.1%, 0.8);
  text-transform: capitalize;
}

.Female {
  color: #f67a7a;
}
.Male {
  color: #67c3ff;
}

.NoDataLabel {
  font-size: 12px;
  color: rgba(161, 161, 161, 0.8);
}
