.collapsibleCard.card {
  width: 100%;
  border: none;
  box-shadow: none;
  margin-bottom: 0.5rem;
}

.collapsibleCard.card-collapsed .card-header {
  background-color: #e9e9e9;
  color: #6f6f6f;
}

.collapsibleCard .card-header {
  width: 100%;
  display: table;
  border: none;
  background-color: #a1a1a1;
  padding: 0;
  color: #fff;
  cursor: pointer;
  z-index: 0;
  transition: all 0.3s ease;
}

.collapsibleCard .card-header:first-child {
  border-radius: 5px;
}

.collapsibleCard .container {
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
}

.profileHandle:before {
  content: '@';
}
