.BottomBar {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 3em;
  box-shadow: 0 0 1px #797979;
  display: flex;
  background-color: #fff;
  justify-content: flex-end;
}

.TotalCount {
  padding: 15px 50px;
  bottom: 0;
  color: #a1a1a1;
  box-shadow: 0px 1px 1px #797979;
  font-size: 14px;
}
