.Image {
  width: 100px;
  height: 100px;
  background-size: cover;
  border-radius: 5px;
}

.ImageContainer {
  padding: 8px;
  margin: 0 8px 0 0;
  background: #a1a1a1;
  border-radius: 5px;
  position: relative;
}

.List {
  background: #e9e9e9;
  display: flex;
  padding: 8px;
  overflow: auto;
  height: 132px;
  border-radius: 5px;
}

.RemoveButton {
  color: #fff;
  background: #dc3545;
  position: absolute;
  top: -5px;
  right: -5px;
  display: block;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border: 3px solid #dc3545;
  border-radius: 100%;
  padding: 2px;
  background: -webkit-linear-gradient(
      -45deg,
      transparent,
      transparent 46%,
      #fff 0,
      #fff 56%,
      transparent 0,
      transparent
    ),
    -webkit-linear-gradient(45deg, transparent, transparent 46%, #fff 0, #fff 56%, transparent 0, transparent);
  background-color: #dc3545;
  transition: all 0.3s ease;
  cursor: pointer;
}

.RemoveButton:hover {
  background-color: #ff152c;
  border-color: #ff152c;
}

.FileName {
  position: absolute;
  bottom: 0;
  color: #fff;
  font-size: 0.8em;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: -8px;
  margin-right: -8px;
  padding: 0 8px;
  background: rgba(0, 0, 0, 0.6);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
