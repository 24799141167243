.ContentPreviewList {
  padding: 20px;
  overflow: scroll;
}

.ContentTitle {
  font-size: 12px;
  font-weight: 700;
  padding: 30px 0 5px;
  margin: 0;
  text-transform: uppercase;
  color: #797979;
}
