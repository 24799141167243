.carousel .slider {
  margin-bottom: 30px;
}

.carousel.carousel-slider .control-arrow {
  opacity: 1;
  padding-right: 0;
}

.carousel.carousel-slider .control-arrow:hover {
  background: transparent;
}

.carousel .control-arrow:before {
  width: 30px;
  height: 30px;
  background-size: 30px;
  border: none;
  padding-right: 0;
}
.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  border: none;
}
.carousel .control-next:before {
  background-image: url('/logos/right_arrow.png');
  background-color: transparent;
}
.carousel .control-prev:before {
  background-image: url('/logos/left_arrow.png');
}
.carousel .carousel-status {
  background: #000;
  border-radius: 15px;
  font-size: 14px;
  padding: 7px 12px;
  margin: 15px;
  text-shadow: none;
  opacity: 0.7;
  letter-spacing: 2px;
}
.carousel .control-dots {
  padding: 0;
}
.carousel .control-dots .dot {
  box-shadow: none;
  width: 6px;
  height: 6px;
  position: relative;
  background-color: #dbdbdb;
  opacity: 1;
  margin: 0 3px;
}
.carousel .control-dots .dot.selected {
  background-color: #3897f0;
}
.carousel .slide {
  display: flex;
  justify-content: center;
  background-color: #fff;
}
