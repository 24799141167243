.QueryFilter {
  margin-top: 0.5rem;
  margin-bottom: 0 !important;
}

.QueryFilter .SearchInput {
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
