.ShortlistedSocialProfileCard {
  width: 32%;
  max-width: 355px !important;
}

.BookedMarker {
  font-size: 30px !important;
  color: #47b87e;
}

.RecommendedMarker {
  color: #fdc421;
}

.ActionsContainer {
  width: 100%;
  min-height: 5vh;
}

.Pointer:active,
.Pointer:focus {
  outline: none;
}

@media (max-width: 1320px) {
  .ShortlistedSocialProfileCard {
    width: 49%;
  }
}

@media (max-width: 1100px) {
  .ShortlistedSocialProfileCard {
    width: 80%;
    margin: 0 auto 10px;
    min-width: 315px !important;
  }
}
