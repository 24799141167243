.detailsImage {
  border-radius: 5px;
  background-size: cover;
  background-color: #e9e9e9;
  height: 250px;
  width: 48%;
  margin: 1%;
  float: left;
}

.profileIcon,
.profileIcon.fa {
  vertical-align: middle;
  font-size: 1.4em;
  cursor: pointer;
}

.profileTitle {
  font-size: 1.1rem;
}

.profileHandle {
  display: block;
  width: fit-content;
  font-size: 0.7em;
  z-index: 100;
}

.collapsibleCard.card-collapsed .card-header,
.collapsibleCard.card-collapsed .profileHandle,
.collapsibleCard.card-collapsed .profileIcon {
  color: #a1a1a1;
  transition: all 0.3s ease;
}

.collapsibleCard .container.selected,
.collapsibleCard .container.selected .profileIcon,
.collapsibleCard .container.selected .profileHandle {
  color: #fff;
}

.collapsibleCard .container.selected {
  border-radius: 5px;
  background-color: rgba(8, 142, 231, 0.6);
}

.collapsibleCard .container:hover {
  border-radius: 5px;
  background-color: #a1a1a1;
  color: #fff;
  transition: all 0.3s ease;
}

.collapsibleCard .card-header,
.card-header:hover,
.card-collapsed .card-header:hover,
.collapsibleCard .profileHandle,
.card-header:hover .profileHandle,
.card-collapsed .card-header:hover .profileHandle,
.collapsibleCard .profileIcon,
.card-header:hover .profileIcon,
.card-collapsed .card-header:hover .profileIcon {
  color: #fff;
  transition: all 0.3s ease;
}

.collapsibleCard .card-body {
  padding: 1rem;
}
